<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>Endoscopy Colonoscopy</h1>
            <p>
              Endoscopy adalah suatu pemeriksaan dengan mengguanakan alat
              seukuran tebal jari telunjuk yang sangat lentur untuk melihat
              kelainan-kelainan pada saluran cerna atas (mulut, esofagus,
              lambung, usus halus) dan saluran cerna bawah ( anus, colon, dan
              usus besar) secara langsung.
            </p>
            <br />

            <p>
              Dengan alat ini juga dapat dilakukan tindakan langsung seperti
              pengambilan jaringan/ biopsi pada bagian yang sakit ( tumor,
              polip, atau kelaianan yang dicurigai) sehingga pengobatan dapat
              lebih cepat.
            </p>
            <br />

            <p>hasil pemeriksaan bisa dokumentasi berupa foto atau VCD.</p>
            <br />
            <p style="font-weight: bold">
              Indikasi keluhan pemeriksaan endoscopy pada :
            </p>
            <p style="font-weight: bold">
              A. Saluran cerna atas/ esofagogastro duodenoscopy?
            </p>
            <p>- Pucat ( anemia)</p>
            <p>
              - kembung, mual, nyeri ulu hati, dyspepsia, yang berkepanjangan
            </p>
            <p>- muntah terus tanpa sebab</p>
            <p>- kesulitan menelan</p>
            <p>- muntah darah atau buang air besar kehitaman</p>
            <p>- tertelan benda asing</p>
            <p>
              - dugaan adanya tumor di saluran cerna atas dari hasil pemeriksaan
              X-ray
            </p>
            <br />

            <p style="font-weight: bold">B. Saluran cerna bawah/ Colonoscopy</p>
            <p>- mencret-mencret selama lebih dari 2 minggu</p>
            <p>- keluar darah dari lubang anus/ dubur</p>
            <p>- memastikan kelainan yang ditemukan pada pemeriksaan X-ray</p>
            <p>
              - nyeri atau rasa tidak nyaman perut terus menerus disertai
              perubahan pada buang air besar
            </p>
            <br />
            <p style="font-weight: bold">
              Apa yang dilakukan selama pemeriksaan colonoscopy?
            </p>
            <p>
              pada tindakan colonoscopy dibutuhkan kesabaran pasien, sering
              muncul perasaan tekanan, perut kembung karena gas atau kram
              sewaktu-waktu selama dilakukan tindakan. dokter akan memberikan
              obat ,elalui vena untuk membantu anda menjadi santai. anda
              berbaring ke samping atau ke depan (terlentang) di saat
              colonoscopy di masukkan naik melalui usus besar. Tindakan tersebut
              biasanya berlangsung sekitar 15-45 menit
            </p>
            <br />

            <p style="font-weight: bold">Setelah tindakan colonoscopy?</p>
            <p>
              Anda perlu istirahat 1-2 jam karena kekembungan udara yang masuk
              sewaktu prosedur tindakan dilakukan akan menjadi lebih nyaman,
              setelah anda keluarkan. jika obat penenang dignakan, anda
              sebaiknya ditemani oleh keluarga atau teman yang bisa bertanggung
              jawab, anda dilarang mengemudi/mengoperasikan mesin/ minum alkohol
              selama 24 jam.
            </p>
            <br />
            <p style="font-weight: bold">Kapan hasilnya diperoleh?</p>
            <p>
              dokter akan memberitahukan kepada anda setelah pemeriksaan
              dilakukan tentang apa yang terlihat dibagian usus besar anda. jika
              dibutuhkan biopsi hasil akan selesai selama 3-5 hari kerja. hasil
              tersebut akan dikirim ke dokter ysng merujuk /mengirimkan.
            </p>
            <br />
            <p style="font-weight: bold">Adakah resiko dan efeknya?</p>
            <p>
              walaupun colonoscopy adalah suatu prosedur yang sederhana. anda
              harus mendiskusikan faktor-faktor komplikasi dan resikonya dengan
              dokter anda atau menghubungi bagian endoscopy ditempat ada akan
              memeriksakan diri.
            </p>
            <br />

            <p style="font-weight: bold">Persiapan</p>
            <p>
              - beritahu dokter atau perawat jika anda memiliki kondisi medis
              seperti; diabetes, hipertensi atau sedang mengkonsumsi obat –
              obatan atau mengalami alergi
            </p>

            <p>
              - puasa, tidak boleh makan dan minum selama 6-8 jam sebelum
              dilakukan tindakan
            </p>

            <p>
              - diet rensdah residu, di tambah dengan obat pencahar untuk
              membersihkan usus besar pada pemeriksaan colonoscopy agar dinding
              usus bagian dalam terlihat dengan jelas.
            </p>

            <p>- pastikan ada persetujuan tertulis dari anda</p>
            <p>
              - melepaskan gigi palsu, kaca mata, perhiasan dan benda-benda
              logam
            </p>

            <p>- sebaiknya ditemani keluarga</p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/endo.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "Endoscopy RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
